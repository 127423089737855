import React from 'react';
import badge from "../assets/badge.png"
export default function AdditivesCard({ title, text }) {
    return (
        <div className="relative flex flex-col w-full h-[250px] md:h-[250px] border rounded-lg shadow-lg  bg-white" style={{ borderRadius: "3px" }}>
            <div className="absolute top-[-2px] right-[-2px] " style={{ borderBottomLeftRadius: "30px" }}>
                <img src={badge} className='h-12 ' />
            </div>
            <div className='mx-5'>
                <h2 className='  text-[21px] mt-8 text-[#0c0c0c] font-semibold font-calibri'>{title}</h2>
                <div className="container flex  text-[18px]  font-calibri">
{text}                </div>
            </div>
        </div>
    );
}
