import React from "react";
import DetailImage from "../assets/detail-image.jpg";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function DetailCompany() {
  return (
    <>
      <Header />
      <div className="bg-[#F5F5F5] min-h-screen pt-4 md:pt-20 pb-10">
        <div className="bg-white mx-4 my-6 md:mx-20  p-4 md:p-12">
          <p className="text-2xl md:text-4xl lg:text-5xl  mb-4 text-[#79ba44]">
            {"BCC - Your Additive Experts "}
          </p>

          <div className="flex flex-col md:flex-row pt-8">
            <div className="md:w-1/2 pr-0 md:pr-8">
              <img
                src={DetailImage}
                className="w-full h-[400]"
                alt="Water base ink "
              />
            </div>
            <div className="md:w-1/2 md:ml-4 mt-4 md:mt-0">
              <p className="text-base md:text-lg lg:text-xl">
                Brother's Chemical company specializes in providing high-quality
                printing chemicals for various printing processes. With a focus
                on innovation and sustainability, we offer a comprehensive range
                of pre-press, printing, and post-press chemicals tailored to
                meet the diverse needs of our customers. Our products include
                emulsions, sensitizers, fountain solutions, ink additives,
                coatings, adhesives, and more. We prioritize environmental
                responsibility by offering eco-friendly formulations and
                adhering to stringent regulatory standards. Our commitment to
                quality, reliability, and customer satisfaction drives us to
                continuously develop cutting-edge solutions that optimize print
                quality, efficiency, and environmental performance. Trust us for
                all your printing chemical needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
