import React from "react";
import whatsapp from "../assets/whatsapp.png"
const CallButton = () => {
    const phoneNumber = '+923007211816'; // Replace with the desired WhatsApp number
    const whatsappURL = `https://wa.me/${encodeURIComponent(phoneNumber)}`;
    return (
        <a
            href={whatsappURL}
            target="_blank"
            rel="noopener noreferrer"
            className="fixed bottom-4 right-4 text-white px-3 py-3 rounded-full shadow-md z-10"

        >
            <img src={whatsapp} height={"50px"} width={"50px"} />
        </a>
    );
};

export default CallButton;
