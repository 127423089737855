// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Detail from './pages/Detail';
import NestedProduct from './pages/NestedProduct';
import DetailCompany from './pages/DetailCompany';


export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/detail" element={<Detail />} />
        <Route path="/detailCompany" element={<DetailCompany />} />
        <Route path="/detail/product" element={<NestedProduct  />} />
      </Routes>
    </Router>
  );
}
